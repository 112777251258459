import { SurveyQuestion } from "@/types/survey";
import { Control, Controller } from "react-hook-form";

import { CheckboxField, RadioField, SelectField, TextField } from "./Fields";

const FIELDS = {
  input: TextField,
  email: TextField,
  radio: RadioField,
  dropdown: SelectField,
  checkbox: CheckboxField,
};

type SurveyStepQuestionProps = {
  question: SurveyQuestion;
  control: Control<any>;
};

export const SurveyStepQuestion = ({ question, control }: SurveyStepQuestionProps) => {
  const Field = FIELDS[(question.type as keyof typeof FIELDS) ?? "input"];
  const id = question.id ?? question.name;

  return (
    <Controller
      control={control}
      name={question.name}
      render={({ field, ...rest }) => (
        <div className="flex flex-col gap-2 z-[1500]">
          <label htmlFor={question.name}>{question.label}</label>
          <Field {...question} {...field} id={id} />
          {rest.formState.errors[question.name] && (
            <span className="text-red-500">
              {rest.formState.errors[question.name]?.message as string}
            </span>
          )}
        </div>
      )}
    />
  );
};
